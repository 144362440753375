<template>
  <div class="about">
    <swiper :banner-list="bannerList"></swiper>
    <div class="content">
      <div class="aboutBox">
        <div class="right">
          <div
            class="introduce"
            v-loading="!pageInfo[0].content"
            v-html="pageInfo[0].content"
          ></div>
        </div>
      </div>
      <div class="interests">
        <div class="showImg" v-loading="!aboutImg.length > 0">
          <el-image
            style="width: 410px; height: 235px;"
            v-for="(item, index) in aboutImg"
            :key="item.id"
            :index="index"
            :src="item.image"
            fit="cover"
            lazy
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <!-- <img
            v-for="(item, index) in aboutImg"
            :key="item.id"
            :index="index"
            :src="item.image"
            alt=""
          /> -->
          <!-- <img src="./../../public/img/about/intesent_img1.png" alt="" />
          <img src="./../../public/img/about/intesent_img2.png" alt="" />
          <img src="./../../public/img/about/intesent_img1.png" alt="" /> -->
        </div>
      </div>
      <div class="group">
        <div class="groupLeaderBox" v-loading="!researchTeam.length > 0">
          <div
            class="groupLeader"
            v-for="(item, index) in researchTeam"
            :key="item.id"
            :index="index"
          >
            <div class="left">
              <el-image
                style="width: 40%; min-height: 150px;"
                :src="item.image || require('./../../public/img/about/portrait.png')"
                fit="cover"
                lazy
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <!-- <img
              :src="item.image || './../../public/img/about/portrait.png'"
              alt=""
            /> -->
            </div>
            <div class="right">
              <div class="introduce" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="aboutBox">
        <div class="right">
          <div
            class="introduce"
            v-loading="!bottomInfo[0].content"
            v-html="bottomInfo[0].content"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swiper from "./../components/swiper.vue";

export default {
  name: "about",
  components: {
    swiper
  },
  data() {
    return {
      pageInfo: {},
      bannerList: [],
      researchTeam: [],
      bottomInfo:[],
      aboutImg: [
        {
          id: 1,
          image: require("./../../public/img/about/intesent_img1.png")
        },
        {
          id: 2,
          image: require("./../../public/img/about/intesent_img2.png")
        },
      ],
    };
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    this.getAbout();
    // this.getContent();
  },
  methods: {
    async getContent() {
      try {
        let params = {
          name:'aboutpc'
        }
        let res = await this.$api.https.getpages(params);
        // let res = await this.$api.https.getMyboutus();
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        this.pageInfo = res.data.pageInfo;
      } catch (err) {
        console.log(err);
      }
    },
    // 获取页面信息
    async getAbout() {
      // 获取swiper
      this.bannerList = await this.getblock("aboutswiper");
      // 获取顶部文本信息
      this.pageInfo = await this.getblock("toptextinfo");
      // 获取about详情图片信息
      this.aboutImg = await this.getblock("aboutimg");
      // 获取团队信息
      this.researchTeam = await this.getblock("aboutresearchteam");
      // 获取底部文本信息
      this.bottomInfo = await this.getblock("bottomtextinfo");
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin title($width) {
  .title {
    text-align: center;
    height: 40px;
    font-size: 36px;
    div:nth-child(2) {
      background: #cbe8ff;
      width: $width;
      height: 11px;
      border-radius: 5px;
      margin: -15px 0 0 0;
    }
  }
}
@mixin introduce() {
  .introduce {
    width: 100%;
    margin-top: 20px;
    line-height: 24px;
    color: #676767;
  }
}
$vue_color: #0495f3;
.about {
  background: #ffffff;
  text-align: left;
  color: #282828;
  font-size: 12px;
  .content {
    padding: 20px 60px;
    .aboutBox {
      display: flex;
      padding: 0 30px 50px;
      .left {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
        width: 256px;
        ::v-deep img {
          width: 123px;
          height: 160px;
          padding-bottom: 10px;
          transition: 0.3s;
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include title($width: 189px);
        @include introduce;
      }
    }
    .interests {
      display: flex;
      flex-direction: column;
      align-items: center;
      > .title {
        background-color: #0b6fbc;
        width: 618px;
        height: 73px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        color: #ffffff;
      }
      .showImg {
        padding: 0 0 50px;
        width: 100%;
        min-height: 282px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        img {
          width: 233px;
          height: 282px;
        }
      }
    }
    .group {
      margin: 0 0 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .groupLeaderBox {
        min-height: 240px;
        padding: 0 30px;
        .groupLeader {
          display: flex;
          align-items: center;
          padding: 30px 0;
          margin: 20px 0;
          border: 2px solid #D4D4D4;
          .left {
            flex: 6;
            padding: 0 30px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 50%;
            }
          }
          .right {
            flex: 6;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @include introduce;
            .introduce{
              box-sizing: border-box;
              margin-top: 0;
              padding-right: 25%;
            }
          }
        }
      }
    }
  }
}
</style>
